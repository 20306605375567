<template>
  <div class="wrapper">
    <div uk-sticky="start: 200; animation: uk-animation-slide-top;show-on-up: true; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky;" class="topbar" >
      <div class="uk-container"  >
      <nav class="uk-navbar-container" uk-navbar>
          <div class="uk-navbar-left">
              <router-link class="homelink" :to="{ name: 'Home' }">{{ $t("global_cmt_enhanced_question_bank") }}</router-link>
          </div>
          <div class="uk-navbar-center">
          

              <button
                v-bind:class="classAll"
                style="border-radius: 5px 5px 0px 0px;margin-bottom:-18px;"
                @click="updateSavedOnly(false)"
              >
                {{ $t("q_all_questions") }}
              </button>
              <button
            
                v-bind:class="classSaved"
                style="border-radius: 5px 5px 0px 0px;margin-bottom:-18px;"
                @click="updateSavedOnly(true)"
              >
                {{ $t("q_saved_questions") }}
                <span class="qcount">{{ savedQuestionsCount }}</span>
              </button>
   

          </div>
          <div class="uk-navbar-right uk-text-right">
              <a
              class="langToggle"
              style="padding:  0px 10px; color: rgba(255,255,255,0.8); text-decoration: underline;"
              v-if="currentLocale == 'fr'"
              @click.stop="updateLocale('en')"
              >English</a
            >
            <a
              class="langToggle"
              style="padding:  0px 10px; color: rgba(255,255,255,0.8); text-decoration: underline;"
              v-if="currentLocale == 'en'"
              @click.stop="updateLocale('fr')"
              >Français</a
            >
              <a style="padding:  0px 10px; color: rgba(255,255,255,0.8); text-decoration: underline;" href="/logout">Sign Out</a>

          </div>
      </nav>
        </div>

      </div>


    <section class="main">
      <div class="uk-container">
        <div >
          <div class="uk-grid uk-grid-collapse">
            <div class="uk-width-1-5@m uk-visible@m unselectable">
              <div class="filters-column" uk-sticky="offset:60px"  v-if="(savedOnly == true && savedQuestionsCount > 0) || (savedOnly == false)">
                <div style="padding-bottom: 16px">
                  <small>Sections</small>
                  <ul class="uk-nav uk-nav-default">
                    <li v-bind:class="classAllActive">
                      <a @click="allGroups()">{{ $t("q_all_sections") }}</a>
                    </li>
                    <group-heading
                      v-for="group in groups"
                      :key="group.id"
                      :group="group"
                    />
                  </ul>
                </div>

                <div
                  v-if="availableChannels.length > 1"
                  style="padding-bottom: 16px"
                >
                  <small>{{ $t("global_channels") }}</small>
                  <div style="padding: 8px 12px">
                    <form>
                      <label
                        v-for="channel in availableChannels"
                        :key="channel.id"
                        style="text-transform: captialize"
                        ><input
                          name="channels"
                          class="uk-checkbox"
                          style="margin-right: 0.2em"
                          type="checkbox"
                          :value="channel"
                          @click="updateFilters"
                          v-model="questionFilters"
                        />&nbsp;
                        <span v-if="channel == 'phone'">{{ $t("phone") }}</span>
                        <span v-if="channel == 'web'">{{ $t("web") }}</span>
                        <span v-if="channel == 'email'">{{ $t("email") }}</span>
                        <span v-if="channel == 'in-person'">{{ $t("in-person") }}</span>
                        <span v-if="channel == 'mail'">{{ $t("mail") }}</span>
                      </label>
                    </form>
                  </div>
                </div>

                <div style="padding-bottom: 16px">
                  <small>{{ $t("global_core_questions") }}</small>
                  <div style="padding: 8px 12px">
                    <label
                      ><input
                        class="uk-checkbox"
                        type="checkbox"
                        value="false"
                        @change="updateCore"
                        v-model="core"
                      />
                      {{ $t("global_core_questions") }}</label
                    >
                  </div>
                </div>
              </div>
            </div>

            <div class="uk-width-1-1 uk-width-4-5@m">
              <div class="questions-column">
                <div v-if="savedOnly == true && savedQuestionsCount > 0" class="uk-float-right">
                  <button
                    class="uk-button uk-button-small uk-button-secondary"
                    @click="doCopy"
                  >
                    {{ $t("copy_to_clipboard") }}</button
                  ><br /><br />
                </div>

 

                <div class="allGroups"  v-if="this.$store.state.activeGroup == '' ">
                  <div v-for="group in this.groups" :key="group.id">
                    <group-questions
                      :group="group"
                      :key="group.id"
                    />
                  </div>
                </div>

                <div v-if="this.$store.state.activeGroup != ''">
                  <group-questions
                    :group="activeGroup"
               
                  />
                </div>

                <div v-if="savedOnly == true && savedQuestionsCount == 0">
                    <div class="group-heading">
    
                      
                      <h2>{{ $t("no_saved_questions") }}</h2>

                      <p><span class="groupnote">{{ $t("no_saved_questions_description") }}</span></p>

                         <div><button
                class="uk-button uk-button-small uk-button-secondary"
               
                @click="updateSavedOnly(false)"
              >{{ $t("q_all_questions") }}</button></div>
                    </div>
                </div>
              </div>
              <div class="uk-text-center"  v-if="savedOnly == true && savedQuestionsCount > 0">
                <button
                  class="uk-button uk-button-small uk-button-secondary"
                  @click="doCopy"
                >
                  {{ $t("copy_to_clipboard") }}</button
                ><br /><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  <!-- This is the modal -->

    <div id="modal-plain" >
      <div class="" ref="plaintext">
      
        <div ref="contentToSelect">
           

          <div
            class="allGroups"
            v-if="this.$store.state.activeGroup == '' "
          >
            <div v-for="group in this.groups" :key="group.id">
              <group-questions :plain="true" :group="group" />
            </div>
          </div>

          <div v-if="this.$store.state.activeGroup" class="activeGroup">
            <group-questions :plain="true" :group="this.$store.state.activeGroup" />
          </div>


        
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Questions",

  data() {
    return {
      locale: "en",
      groups: [],
      availableChannels: ["phone", "in-person", "web", "mail", "email"],
      surveyScope: "all",
      questionFilters: ["phone", "in-person", "web", "mail", "email"],
      channelLabelsEn: {
        phone: '{{ $t("phone") }}',
        "in-person": '{{ $t("in-person") }}',
        web: '{{ $t("web") }}',
        mail: '{{ $t("mail") }}',
        email: '{{ $t("email") }}'
      },
      core: false,
      savedOnly: false

    };
  },
  computed: {
    currentLocale() {
      return this.$root.$i18n.locale;
    },
    activeGroup() {
      return this.$store.state.activeGroup;
    },
    classAllActive: function () {
      return {
        "uk-active": this.activeGroup == "",
        "": this.activeGroup != "",
      };
    },

    classAll: function () {
      return {
        "uk-button uk-button-secondary": this.savedOnly == false,
        "uk-button uk-button-primary": this.savedOnly == true,
      };
    },
    classSaved: function () {
      return {
        "uk-button uk-button-secondary": this.savedOnly == true,
        "uk-button uk-button-primary": this.savedOnly == false,
      };
    },
    savedQuestionsCount() {
      return this.$store.state.savedQuestions.length;
    },
    savedQuestions: {
      get() {
        return this.$store.state.savedQuestions;
      },
      set(value) {
        this.$store.commit("UPDATE_SAVED_QUESTIONS", value);
        this.$store.dispatch('setUserData');
      },
    },
  },
  methods: {
 

    doCopy: function () {
      var element = this.$refs.contentToSelect;
    
    
      var range;
      if (document.selection) {
        // IE
        range = document.body.createTextRange();
        range.moveToElementText(element);
        range.select();
      } else if (window.getSelection) {
        range = document.createRange();
        range.selectNode(element);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
      }


    
      document.execCommand("copy");
      alert("Copied to clipboard!");
     

     

      if (window.getSelection) {
        if (window.getSelection().empty) {
          // Chrome
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          // Firefox
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        // IE?
        document.selection.empty();
      }
    },

    updateLocale(payload) {
      this.$i18n.locale = payload;
      const currentGroupRef = this.$store.state.activeGroup.ref;
   
      axios
        .get("/question-bank-" + payload + ".json")
        .then((response) => {
          this.groups = response.data.groups;
          if (currentGroupRef) {
            this.groups.forEach((g) => {
              if (g.ref == currentGroupRef) {
                this.$store.commit("UPDATE_ACTIVE_GROUP", g);
              }
            });
          } else {
            this.$store.commit("UPDATE_ACTIVE_GROUP", this.groups[0]);
          }
        });
    },

    allGroups() {
      this.$store.commit("UPDATE_ACTIVE_GROUP", "");
      window.scrollTo(0, 0);
    },
    updateFilters() {
      const channelArray = [];
      const channels = event.target.form.channels;
      channels.forEach(function (c) {
        if (c.checked) {
          channelArray.push(c.value);
        }
      });

      this.$store.commit("UPDATE_QUESTION_FILTERS", channelArray);
      this.questionFilters = this.$store.state.questionFilters;
    },
    updateCore() {
      this.$store.commit("UPDATE_CORE", this.core);
      this.core = this.$store.state.core;
    },
    updateSavedOnly(val) {
      this.$store.commit("UPDATE_SAVED_ONLY", val);
      this.savedOnly = this.$store.state.savedOnly;
      this.$store.commit("UPDATE_ACTIVE_GROUP", "");
      window.scrollTo(0, 0);

    },
    hasSaved(questions) {
      var response = "notSaved";
      var saved = this.$store.state.savedQuestions;
      questions.forEach(function (q) {
        var id = String(q.id);
        if (saved.includes(id)) {
          response = "Saved";
        }
      });

      return response;
    },
  },


  created() {
    this.availableChannels = this.$store.state.availableChannels;
    this.questionFilters = this.$store.state.questionFilters;
    this.surveyScope = this.$store.state.surveyScope;
    this.core = this.$store.state.core;
    this.savedOnly = this.$store.state.savedOnly;

    axios
      .get("/question-bank-" + this.$i18n.locale + ".json")
      .then((response) => {
        this.groups = response.data.groups;
        this.$store.commit("UPDATE_ACTIVE_GROUP", "");
      });
  },

  mounted() {

    if (typeof window.userSavedQuestions !== 'undefined') {
      this.$store.commit("SET_SAVED_QUESTIONS",window.userSavedQuestions);
    }
  }
};
</script>

<style lang="scss">
$brand-colour: #5b489e;
   .topbar  .uk-navbar-container, .uk-navbar-container:not(.uk-navbar-transparent) {
       background: transparent !important;
   }

  .topbar {
   padding: 12px 10px;
   background-color: #5b489e ;
   color: rgba(255,255,255,0.8);

  }


.topbar .uk-button-primary, .topbar .uk-button-primary:focus, .topbar .uk-button-primary:hover {
  background: transparent;
  border:none;
}

 .topbar .uk-button-primary:disabled, .uk-button-secondary:disabled {
    background-color: transparent;
    color: inherit;
    border: none !important;

}


.topbar .uk-button-secondary {
  background: #f8f8f8;
  color: $brand-colour;
  border: none;
}
.topbar .uk-button-secondary:hover {
  color: $brand-colour;
  background: lighten($brand-colour, 40);
   border: none;
}

.topbar .uk-button-secondary:active,
.topbar .uk-button-secondary:focus {
  background: #f8f8f8;
  color: $brand-colour;
  border: none;
}



label {
  display: block;
}

.filters-column {
  padding: 70px 20px 80px 0;
}

.filters-column.uk-sticky-fixed {
  padding: 60px 20px 20px 0;
}

.questions-column {
  margin-top: 40px;
  padding: 0;
  @media (min-width: 960px) {
    padding: 0 40px;
  }
 
}

.questionList,
.questionList li,
.answerList,
.answerList li {
  list-style-type: none;
}

.q {
  font-weight: 700;
  color: $brand-colour;
}

.textLine,
.textareaLines {
  color: #ccc;
}

.uk-label {
  background: rgb(128, 161, 196);
  font-size: 12px;
  cursor: pointer;
}
.question {
  position: relative;
}
.question > .uk-icon {
  position: absolute;
  left: -2em;
  top: 0.2em;
  color: #527397;
}

.uk-table th strong {
  color: #333;
}

.circle {
  display: inline-block;
  background-color: #f8f8f8;
  border: 1px solid rgb(233, 233, 233);
  height: 0.6em;
  width: 0.6em;
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  margin: 0;
}

.square {
  display: inline-block;
  background-color: #f8f8f8;
  border: 1px solid rgb(233, 233, 233);
  height: 0.6em;
  width: 0.6em;
}

.uk-nav-default > li {
  color: #527397;
}
.uk-nav-default > li > a {
  color: #333;
  font-size: 15px;
  padding: 2px 8px;
  margin: 4px 0;
  border-left: 3px solid transparent;
}
.uk-nav-default > li > a:hover {
  background-color: rgba(91, 72, 158, 0.05);
  color: $brand-colour;
}

.uk-nav-default > li.uk-active > a {
  background-color: rgba(91, 72, 158, 0.05);
  color: $brand-colour;
  border-left: 3px solid $brand-colour;
}

.qcount {
  padding: 1px 8px 2px 8px;
  background: lighten(#5b489e, 45);
  color: #5b489e;

  font-size: 14px;
  border-radius: 3px;
  margin-left: 3px;
  vertical-align: bottom;
}

.uk-button-primary .qcount {
  background: rgba(29,21,58,0.3);
  color: #fff;
}

.homelink {
  color: rgba(255,255,255,0.8);
  font-size: 20px;
  font-weight: 200;
}

.homelink:hover, .homelink:focus {
  color: rgba(255,255,255,1);
  font-size: 20px;
  font-weight: 200;
  text-decoration: none;
}

.uk-button-secondary {
    color: #5b489e;
    background: #d1cbe7;
    border: none;
}
.uk-button-secondary:hover, .uk-button-secondary:focus {
    color: #fff;
    background: #5b489e;
    border: none;
}

#modal-plain {
 
  width: 1px;
  height: 1px;
  overflow: hidden;
}
 .group-heading {
  padding: 10px; 
  text-align: left;

}
 .group-heading h2 {

    margin: -6px 0 0px 0;
    font-weight: 200;
    letter-spacing: 0.03em;
    color: #584B99;
    font-size: 1.8em;
}


.group-heading small {
    font-weight: 400;
    font-size: 15px;
    opacity: 0.6;
    color: #3c3c3c;
  }

  .groupnote {
    font-weight: 300;
    font-size: 1.2em;
  }
</style>
