import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import VuexPersist from "vuex-persist";
Vue.use(Vuex, vuexPersist);
const vuexPersist = new VuexPersist({
  key: "cmt-settings",
  storage: window.localStorage
});
export default new Vuex.Store({

  plugins: [vuexPersist.plugin],
  
  state: {

    availableChannels: [],
    savedQuestions: [],
    questionFilters: [],
    surveyScope: "primary",
    clients: "external",
    core: false,
    savedOnly: false,
    activeGroup: Object
  },
  
  
  mutations: {
    UPDATE_AVAILABLE_CHANNELS(state, payload) {
      state.availableChannels = payload;
    },
    ADD_SAVED_QUESTION(state, payload) {
      if(state.savedQuestions.includes(payload) == false){
        state.savedQuestions.push(payload);
      }
    },
    SET_SAVED_QUESTIONS(state, payload){
      state.savedQuestions = payload;
    },

    REMOVE_SAVED_QUESTION(state, payload) {
      const index = state.savedQuestions.indexOf(payload);
      if (index > -1) {
        state.savedQuestions.splice(index, 1);
      }   
    },
    UPDATE_QUESTION_FILTERS(state, payload) {
      state.questionFilters = payload;
    },
    UPDATE_SURVEY_SCOPE(state, payload) {
      state.surveyScope = payload;
    },
    UPDATE_CLIENTS(state, payload) {
      state.clients = payload;
    },
    UPDATE_CORE(state, payload) {
      state.core = payload;
    },
    UPDATE_SAVED_ONLY(state, payload) {
      state.savedOnly = payload;
    },
    UPDATE_ACTIVE_GROUP(state, payload) {
      state.activeGroup = payload;
    }
  },
  actions: {

    async setUserData({ context, state }) {

      let questionArray = state.savedQuestions;
      let arrayString = '';
      questionArray.forEach(q => {
        arrayString += `"${q}",`;
      }); 
      arrayString = arrayString.replace(/,\s*$/, "");
      arrayString = `[${arrayString}]`;

      let payload = { 
        "CRAFT_CSRF_TOKEN": window.csrfToken,
        "userId": parseInt(window.userId),    
        "fields": {"cmtQuestionBankData": `{ "savedQuestions":  ${arrayString} }`}
      } 
    
      fetch("https://cmt.citizenfirst.ca/?action=users/save-user", {
          method: "POST",
          headers: {
              "Accept": "application/json",
              "Content-Type": "application/json"
          },
          body: JSON.stringify(payload)
      })
         .then(response => console.log(response))
         .then(response => console.log(response))

    },
  },
  modules: {}
});


// store/index.js
/*
import { createStore } from "vuex";
import axios from "axios";
export default createStore({
  state: {
    users: [],
  },
  getters: {
    getUsers: (state) => state.users,
  },
  actions: {
    async fetchUsers({ commit }) {
      try {
        const data = await axios.get(
          "https://jsonplaceholder.typicode.com/users"
        );
        commit("SET_USERS", data.data);
      } catch (error) {
        alert(error);
        console.log(error);
      }
    },
  },
  mutations: {
    SET_USERS(state, users) {
      state.users = users;
    },
  },
});*/