<template>
  <div style="border-top: 8px solid #5b489e" >
    <div class="uk-container header">
      <div class="uk-grid">
        <div class="uk-width-1-2">
          <div>
            <router-link :to="{ name: 'Home' }">
              <small>{{ $t("global_cmt") }}</small>
              <h1>{{ $t("global_enhanced_question_bank") }}</h1>
            </router-link>

            <a
              class="langToggle"
        
              v-if="currentLocale == 'fr'"
              @click.stop="updateLocale('en')"
              >English</a
            >
            <a
              class="langToggle"

              v-if="currentLocale == 'en'"
              @click.stop="updateLocale('fr')"
              >Français</a
            >
          </div>
        </div>
      </div>

      <div class="home">
        <h2>{{ $t("home_title") }}</h2>
        <div v-html="$t('home_description')"></div>

        <div class="card">
          <div class="uk-grid uk-grid-large uk-child-width-1-3@m">
            <div>
              <p>
                <small>1. {{ $t("home_available_channels") }}</small>
                <span v-html="$t('home_available_channels_description')"></span>
              </p>

              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  value="phone"
                  name="channels"
                  v-model="availableChannels"
                />
                {{ $t("phone") }}
              </label>

              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  value="in-person"
                  name="channels"
                  v-model="availableChannels"
                />
                {{ $t("in-person") }}
              </label>

              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  value="web"
                  name="channels"
                  v-model="availableChannels"
                />
                {{ $t("web") }}
              </label>

              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  value="mail"
                  name="channels"
                  v-model="availableChannels"
                />
                {{ $t("mail") }}
              </label>

              <label>
                <input
                  class="uk-checkbox"
                  type="checkbox"
                  value="email"
                  name="channels"
                  v-model="availableChannels"
                />
                {{ $t("email") }}
              </label>
            </div>

            <div v-if="availableChannels.length > 1">
              <p>
                <small>2. {{ $t("scope_of_survey") }}</small>
                {{ $t("what_is_the_scope") }}
              </p>

              <label>
                <input
                  name="scope"
                  class="uk-radio"
                  type="radio"
                  value="primary"
                  v-model="scope"
                />
                &nbsp;<strong>{{ $t("single_channel_bold") }}</strong
                ><br />{{ $t("single_channel") }}
              </label>

              <label>
                <input
                  name="scope"
                  class="uk-radio"
                  type="radio"
                  value="all"
                  v-model="scope"
                />
                &nbsp;<strong>{{ $t("multi_channel_bold") }}</strong
                ><br />{{ $t("multi_channel") }}
              </label>
            </div>

            <div>
              <p>
                <small
                  ><span v-if="availableChannels.length > 1">3.</span
                  ><span v-if="availableChannels.length < 2">2.</span>
                  {{ $t("audience") }}</small
                >
                {{ $t("what_audience") }}
              </p>

              <label>
                <input
                  name="clients"
                  class="uk-radio"
                  type="radio"
                  value="external"
                  v-model="clients"
                />
                {{ $t("external") }}
              </label>

              <label>
                <input
                  name="clients"
                  class="uk-radio"
                  type="radio"
                  value="internal"
                  v-model="clients"
                />
                {{ $t("internal") }}
              </label>
            </div>
          </div>
        </div>

        <input
          type="submit"
          @click="saveSettings"
          class="uk-button uk-button-primary"
          :value="nextButtonText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Home",
  
  data() {
    return {
      groups: [],
      ac: [],
      availableChannels: ["test"],
      scope: "primary",
      clients: "external",
      core: false,
      nextButtonText: "NEXT",
    };
  },
  methods: {
    updateLocale(payload) {

      this.$i18n.locale = payload;
  
      if (payload == "fr") {
        this.nextButtonText = "SUIVANT";
      } else {
        this.nextButtonText = "NEXT";
      }
    },
    saveSettings() {
      this.$store.commit("UPDATE_AVAILABLE_CHANNELS", this.availableChannels);
      this.$store.commit("UPDATE_QUESTION_FILTERS", this.availableChannels);
      this.$store.commit("UPDATE_SURVEY_SCOPE", this.scope);
      this.$store.commit("UPDATE_CLIENTS", this.clients);
      this.$router.push({ name: "Questions" });
    },


  
  },
  computed: {
    currentLocale() {
      return this.$root.$i18n.locale;
    },
  },
  created() {
    this.availableChannels = this.$store.state.availableChannels;
    this.scope = this.$store.state.surveyScope;
    this.clients = this.$store.state.clients;


    
  },
  mounted() {

    
  }

};
</script>

<style lang="scss" scoped>
h1 {
  margin-bottom: 0.25em;
}
a.langToggle {
  margin-top: -20px;
  text-decoration: underline;
  font-size: 90%;
  color: #5b489e;
}

$brand-colour: #5b489e;
.home {
  padding: 40px 0;
}
h1 {
  margin-bottom: 0.25em;
}

h2 {
  font-size: 1.7em;
  font-weight: 300;
  color: #5b489e;
}
.card {
  padding: 40px;
  margin: 40px 0;
  small {
    font-weight: 700;
    text-transform: uppercase;
    color: $brand-colour;
    font-size: 0.9em;
    display: block;
    padding-bottom: 3px;
  }
}

label {
  display: block;
  padding: 4px 20px;
}
.uk-button-primary {
  padding: 5px 80px;
}
.uk-button-secondary {
  padding: 5px 80px;
  background: #f4f2f8;
  color: $brand-colour;
}
</style>
