import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";


import axios from 'axios'
import { stringify } from 'qs'

const axiosInstance = axios.create({
    headers: {
        'X-CSRF-Token': window.csrfToken,
    },
    transformRequest: [
        function(data) {
            return stringify(data)
        },
    ]
})

Vue.prototype.$axios = axiosInstance;



import QuestionList from "@/components/QuestionList";
Vue.component("question-list", QuestionList);

import GroupHeading from "@/components/GroupHeading";
Vue.component("group-heading", GroupHeading);

import GroupQuestions from "@/components/GroupQuestions";
Vue.component("group-questions", GroupQuestions);

import AnswerList from "@/components/AnswerList";
Vue.component("answer-list", AnswerList);

import Question from "@/components/Question";
Vue.component("question", Question);

import QuestionPlain from "@/components/QuestionPlain";
Vue.component("question-plain", QuestionPlain);

import ChannelTags from "@/components/ChannelTags";
Vue.component("channel-tags", ChannelTags);

import GlobalHeader from "@/components/GlobalHeader";
Vue.component("global-header", GlobalHeader);

Vue.config.productionTip = false;



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
