<template>
  <div style="background: #fff;" >
     
<div v-if="question.id === null" class="qid">
            <strong  >Follow-up Question - </strong>
            <span v-if="question.type == 'single'"  >Radio Buttons</span>
            <span v-if="question.type == 'multiple'" >Checkboxes</span>
            <span v-if="question.type == 'text'" >Text</span>
            <span v-if="question.type == 'textarea'"  >Textarea</span>
            <span v-if="question.type == 'number'"  >Number</span>
            <span v-if="question.type == 'scale'" >Scaled</span>
</div>


    <div v-if="question.id !== null" class="qid">
      <strong v-if="question.id" >Question {{ question.id }} - </strong>
      <span v-if="question.type == 'single'"  >Radio Buttons</span>
      <span v-if="question.type == 'multiple'" >Checkboxes</span>
      <span v-if="question.type == 'text'" >Text</span>
      <span v-if="question.type == 'textarea'"  >Textarea</span>
      <span v-if="question.type == 'number'"  >Number</span>
      <span v-if="question.type == 'scale'" >Scaled</span></div>

     
    <span class="q">{{ question.q }}</span>


    <answer-list
      v-if="question.type == 'single' || question.type == 'multiple'"
      :answers="question.answers"
      :qtype="question.type"
      :channels="channels"
      :core="core"
      :plain="plain"
    />

    <div v-if="question.type == 'scale'" >
          
          <div style="padding: 20px 40px;">
          <strong>Scales</strong>  (Rated 1 through 5, or NA)
            <ol>
           <li
              v-for="scale in question.scales"
              :key="scale.id"
       
              
            >
              <strong>{{ scale.label }}</strong> ( 1={{ scale.lowLabel }}, 5={{ scale.highLabel }} )
                
            </li>
            </ol>
               <strong>Statements</strong>  
            <ul>
          <li v-for="s in filteredStatements(question.statements)" :key="s.id" >
           
              <span v-if="question.id" class="qid"
                >Statement {{ s.id }}<br
              /></span>
              <span class="q">{{ s.statement }}</span>
              
          </li>
          </ul>
    </div>
    </div>
    </div>

</template>

<script>
export default {
  name: "QuestionPlain",

  props: {
    question: {
      type: Object
    },
    statement: {
      type: Object
    },
    channels: {
      type: Array
    },
    ac: {
      type: Array
    },
    core: {
      type: Boolean
    },
    plain: {
      type: Boolean,
      default: true
    }

  },
  data() {
    return {
      savedQuestions: []
    };
  },

  methods: {
   
    filteredStatementsOld(statements) {
      var fs = [];

      if (this.$store.state.core === true) {
        fs = statements.filter(statement => statement.core);
      } else {
        fs = statements;
      }
      return fs;
    },
    filteredStatements(statements) {
      var fq = [];
      var fq2 = [];

      var questionFilters = this.$store.state.questionFilters;
      var showSavedOnly = this.$store.state.savedOnly;
      var saved = this.$store.state.savedQuestions;

      if (this.$store.state.core === true) {
        fq = statements.filter(statement => statement.core);
      } else {
        fq = statements;
      }

      fq.forEach(function(s) {
        var include = false;
        questionFilters.forEach(function(c) {
          if (s.channels.includes(c)) {
            include = true;
          }
        });

        if (showSavedOnly) {
          if (include == true) {
            if (saved.includes(s.id)) {
              include = true;
            } else {
              include = false;
            }
          }
        }

        if (include == true) {
          fq2.push(s);
        }
      });

      return fq2;
    },
    updateSavedQuestions() {
      var saved = this.$store.state.savedQuestions;

      if (event.target.checked) {
        this.$store.commit("ADD_SAVED_QUESTION", event.target.value);
      }
      if (event.target.checked == false) {
        this.$store.commit("REMOVE_SAVED_QUESTION", event.target.value);
        // find saved statements that begin with this value

        var children = saved.filter(id => id.startsWith(event.target.value));



        for (var i = 0; i < children.length; i++) {
          this.$store.commit("REMOVE_SAVED_QUESTION", children[i]);
        }
      }

      //this.savedQuestions = this.$store.state.savedQuestions;
    },

    updateSavedStatements() {
      if (event.target.checked) {
        var id = event.target.value;
        var parentId = id.substr(0, id.lastIndexOf("."));

        this.$store.commit("ADD_SAVED_QUESTION", id);
        this.$store.commit("ADD_SAVED_QUESTION", parentId);
      }
      if (event.target.checked == false) {
        this.$store.commit("REMOVE_SAVED_QUESTION", event.target.value);
      }

      //this.savedQuestions = this.$store.state.savedQuestions;
    }
  },
  created() {
    this.savedQuestions = this.$store.state.savedQuestions;
  }
};
</script>

<style lang="scss">
.question {
  margin-top: 2em;
  position: relative;
  border-top: 3px solid #584B99;
}

.question .question {
  box-shadow: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  margin-left: 6px;
  .question-content {
    padding: 20px 0 20px 20px;
  }
}

.question-meta {
  background: #EEECF5;
  padding: 7px 9px ;
  label span {
    line-height:0px;
    font-size: 14px;
    color: #666;
  }
  label:hover span {
    color: #5b489e;
    cursor: pointer;
  }
  label:hover .uk-checkbox {
    border-color: #5b489e;
    
  }
}
.question .question .question-meta {
  background: #F9F8FC;
}

.question-content {
  padding: 40px;
}

.question .questionList .question {
  margin-top: 10px;
}

.questionType {
    margin: 0em 1em 1em 2em;
  float: right;
}
.questionNote {
  font-size: 0.9em;
  font-style: italic;
  color: #333;
  float: right;
  margin: 0em 1em 1em 2em;
  width: 18em;
  strong {
    
    font-style: normal;
    color: #555;
    font-weight: 500;
  }
}
thead {
  background: transparent;
  color: #5b489e;
}
.uk-table th {
 color: #5b489e;
 font-size: 12px;
 padding: 5px 10px;
}
.uk-table th strong {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 500;
  color: #5b489e;
}
.lines {
  color: #ddd;
  padding: 0 30px 20px 30px;
}
.question .uk-label {
  margin-right: 0.3em;
}



.savestatement {
  margin-top: 28px;
}
.qid {
  color: #999;
  letter-spacing: 1.1;
  font-size: 12px;
  text-transform: uppercase;
}

.question .uk-checkbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;
 
}

.uk-table-striped tbody tr:nth-of-type(odd), .uk-table-striped>tr:nth-of-type(odd) {
    background: #F9F8FC;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
</style>
