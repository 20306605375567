<template>
  <div class="question card">
    <div class="question-meta unselectable"  >
      <div class="uk-grid uk-grid-collapse uk-flex ">
        <div  class="uk-flex-middle">
          
          
          <div v-if="question.id">
            <label class="unselectable"><input
              type="checkbox"
              name="savedQuestions"
              :value="question.id"
              @click="updateSavedQuestions"
              v-model="savedQuestions"
              class="uk-checkbox savequestion unselectable"
            />
            <span>&nbsp;{{ $t('save_question') }}</span></label>
          </div>

          <div v-if="question.id == null" class="qid unselectable" style="user-select: none">
            <strong  >{{ $t('follow_up_question') }} - </strong>
            <span v-if="question.type == 'single'"  >{{ $t('type_radio_buttons') }}</span>
            <span v-if="question.type == 'multiple'" >{{ $t('type_checkboxes') }}</span>
            <span v-if="question.type == 'text'" >{{ $t('type_text') }}</span>
            <span v-if="question.type == 'textarea'"  >{{ $t('type_textarea') }}</span>
            <span v-if="question.type == 'number'"  >{{ $t('type_number') }}</span>
            <span v-if="question.type == 'scale'" >{{ $t('type_scaled') }}</span></div>
          </div>
        
          <div class="uk-width-expand  uk-text-right unselectable" style="user-select: none">
            <channel-tags
            :channels="channels"
            :item="question"
            :ac="ac"
          />&nbsp;&nbsp;
          </div>
      </div>
    </div>

    <div class="question-content">
    <div v-if="question.id" class="qid">
      <strong v-if="question.id" >Question {{ question.id }} - </strong>
   
      <span v-if="question.type == 'single'"  >{{ $t('type_radio_buttons') }}</span>
      <span v-if="question.type == 'multiple'" >{{ $t('type_checkboxes') }}</span>
      <span v-if="question.type == 'text'" >{{ $t('type_text') }}</span>
      <span v-if="question.type == 'textarea'"  >{{ $t('type_textarea') }}</span>
      <span v-if="question.type == 'number'"  >{{ $t('type_number') }}</span>
      <span v-if="question.type == 'scale'" >{{ $t('type_scaled') }}</span></div>

        <div  class="questionNote uk-text-muted">

      <p v-if="question.notes" class="unselectable" draggable="false">
       
        {{ question.notes }}
      </p>
    </div>
    <span class="q selectall">{{ question.q }}</span>



    <div v-if="question.type == 'text'" class="lines">
      _____________________
    </div>

    <div v-if="question.type == 'textarea'" class="lines">
      __________________________________________<br />
      __________________________________________
    </div>
    <div v-if="question.type == 'number'" class="lines">_______</div>

    <answer-list
      v-if="question.type == 'single' || question.type == 'multiple'"
      :answers="question.answers"
      :qtype="question.type"
      :channels="channels"
      :core="core"
      :plain="plain"
    />

    <div v-if="question.type == 'scale'" class="scaledList">
      <table class="uk-table uk-table-striped ">
        <thead>
          <tr>
            <th colspan="2"><strong>{{ $t('statement') }}</strong></th>
            <th
              v-for="scale in question.scales"
              :key="scale.id"
              class="uk-text-center"
              width="180px"
              
            >
              <strong>{{ scale.label }}</strong>
              <div class="uk-grid">
                <div class="uk-width-1-2 uk-text-left ">
                  {{ scale.lowLabel }}
                </div>
                <div class="uk-width-1-2 uk-text-right ">
                  {{ scale.highLabel }}
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="s in filteredStatements(question.statements)" :key="s.id" style="border-top: 1px solid #CFC9E2 !important;border-bottom: 1px solid #CFC9E2 !important;">
            <td
              style="
               
                text-wrap: no-wrap;
                vertical-align: top;
                text-align: right;
              "
            >
              <input
                v-if="s.id"
                type="checkbox"
                name="savedQuestions"
                :value="s.id"
                @click="updateSavedStatements"
                v-model="savedQuestions"
                class="uk-checkbox savestatement"
              />
            </td>
            <td style="text-wrap: no-wrap; vertical-align: top">
              <span v-if="question.id" class="qid"
                >{{ $t("statement") }} {{ s.id }}<br
              /></span>
              <span class="q selectall">{{ s.statement }}</span>
              <div class=" uk-text-muted unselectable">
                <channel-tags :channels="channels" :item="s" :ac="ac" />
              </div>
            </td>
            <td
              valign="middle"
              v-for="scale in question.scales"
              :key="scale.id"
              class="uk-text-center unselectable"
              style="
                border-left: 1px solid #F9F8FC;
                border-right: 1px solid #F9F8FC;
                text-wrap: no-wrap;
                vertical-align: middle;
              "
            >
              <span
                v-for="index in 5"
                :key="index"
                style="display: inline-block; margin: 0 0.5em 0 0"
              >
                {{ index }}
              </span>
              <span
                ><abbr v-if="scale.includeNA" title="Not Applicaable"
                  >N/A</abbr
                ></span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Question",

  props: {
    question: {
      type: Object
    },
    statement: {
      type: Object
    },
    channels: {
      type: Array
    },
    ac: {
      type: Array
    },
    core: {
      type: Boolean
    }, 
    plain: {
      type: Boolean,
      default: false
    }
   
  },
  data() {
    return {
      savedQuestions: []
    };
  },

  methods: {
    filteredStatementsOld(statements) {
      var fs = [];

      if (this.$store.state.core === true) {
        fs = statements.filter(statement => statement.core);
      } else {
        fs = statements;
      }
      return fs;
    },
    filteredStatements(statements) {
      var fq = [];
      var fq2 = [];

      var questionFilters = this.$store.state.questionFilters;
      var showSavedOnly = this.$store.state.savedOnly;
      var saved = this.$store.state.savedQuestions;

      if (this.$store.state.core === true) {
        fq = statements.filter(statement => statement.core);
      } else {
        fq = statements;
      }

      fq.forEach(function(s) {
        var include = false;
        questionFilters.forEach(function(c) {
          if (s.channels.includes(c)) {
            include = true;
          }
        });

        if (showSavedOnly) {
          if (include == true) {
            if (saved.includes(s.id)) {
              include = true;
            } else {
              include = false;
            }
          }
        }

        if (include == true) {
          fq2.push(s);
        }
      });

      return fq2;
    },
    updateSavedQuestions() {
      var saved = this.$store.state.savedQuestions;

      if (event.target.checked) {
        this.$store.commit("ADD_SAVED_QUESTION", event.target.value);
        this.$store.dispatch('setUserData');
      }
      if (event.target.checked == false) {
        this.$store.commit("REMOVE_SAVED_QUESTION", event.target.value);

        // find saved statements that begin with this value

        var children = saved.filter(id => id.startsWith(event.target.value));



        for (var i = 0; i < children.length; i++) {
          this.$store.commit("REMOVE_SAVED_QUESTION", children[i]);
        }

        this.$store.dispatch('setUserData');
      }

      //this.savedQuestions = this.$store.state.savedQuestions;
    },

    updateSavedStatements() {
      if (event.target.checked) {
        var id = event.target.value;
        var parentId = id.substr(0, id.lastIndexOf("."));

        this.$store.commit("ADD_SAVED_QUESTION", id);
        this.$store.commit("ADD_SAVED_QUESTION", parentId);
      }
      if (event.target.checked == false) {
        this.$store.commit("REMOVE_SAVED_QUESTION", event.target.value);
      }

      //this.savedQuestions = this.$store.state.savedQuestions;
    }
  },
  created() {
    this.savedQuestions = this.$store.state.savedQuestions;
  }
};
</script>

<style lang="scss">
.question {
  margin-top: 2em;
  position: relative;
  border-top: 3px solid #584B99;
}

.question .question {
  box-shadow: none;
  border-right: none;
  border-bottom: none;
  border-top: none;
  margin-left: 6px;
  .question-content {
    padding: 20px 0 20px 20px;
  }
}

.question-meta {
  background: #EEECF5;
  padding: 7px 9px ;
  label span {
    line-height:0px;
    font-size: 14px;
    color: #666;
  }
  label:hover span {
    color: #5b489e;
    cursor: pointer;
  }
  label:hover .uk-checkbox {
    border-color: #5b489e;
    
  }
}
.question .question .question-meta {
  background: #F9F8FC;
}

.question-content {
  padding: 40px;
}

.question .questionList .question {
  margin-top: 10px;
}

.questionType {
    margin: 0em 1em 1em 2em;
  float: right;
}
.questionNote {
  font-size: 0.9em;
  font-style: italic;
  color: #333;
  float: right;
  margin: 0em 1em 1em 2em;
  width: 18em;
  strong {
    
    font-style: normal;
    color: #555;
    font-weight: 500;
  }
}
thead {
  background: transparent;
  color: #5b489e;
}
.uk-table th {
 color: #5b489e;
 font-size: 12px;
 padding: 5px 10px;
}
.uk-table th strong {
  color: #3c3c3c;
  font-size: 16px;
  font-weight: 500;
  color: #5b489e;
}
.lines {
  color: #ddd;
  padding: 0 30px 20px 30px;
}
.question .uk-label {
  margin-right: 0.3em;
}



.savestatement {
  margin-top: 28px;
}
.qid {
  color: #999;
  letter-spacing: 1.1;
  font-size: 12px;
  text-transform: uppercase;
}

.question .uk-checkbox {
  height: 20px;
  width: 20px;
  border-radius: 3px;
 
}

.uk-table-striped tbody tr:nth-of-type(odd), .uk-table-striped>tr:nth-of-type(odd) {
    background: #F9F8FC;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
</style>
