<template>

  <div class="questionList">

      
    <div  v-for="question in filteredQuestions" :key="question.id">
      <div v-if="plain == true">

        <question-plain :plain="plain"  :question="question" />
      </div>
      <div v-else >
 
        <question  :question="question" />
      </div>
    </div>
    
    </div>


</template>

<script>
export default {
  name: "QuestionList",
  props: {
    questions: {
      type: Array
    },
    subquestion: {
      type: String
    },
    plain: {
      default: false,
      type: Boolean
    }
  },
  computed: {
filteredQuestions() {
      var fq = [];
      var fq2 = [];
   
      var questionFilters = this.$store.state.questionFilters;
      var showSavedOnly = this.$store.state.savedOnly;
      var saved = this.$store.state.savedQuestions;
      
      if (this.$store.state.core === true) {
        fq = this.questions.filter(q => q.core);
      } else {
        fq = this.questions;
      }

      fq.forEach(function(q) {
        var include = false;

      

        questionFilters.forEach(function(c) {
          if ( q.channels.includes(c) ) {
            include = true;
          }
        
        });

        if(showSavedOnly){
          
          if(include == true){

              //get the top level question ID

            if (saved.includes(q.id) || q.id == null){
              include = true;
            } else {

              include = false;
            
            }
          }
        }

        

        if(include === true) {
          fq2.push(q);
        }
      });




      return fq2;
    },
    filteredQuestions2() {
   
      // TO DO: Add checks for Core questions

      let saved = this.$store.state.savedQuestions;
      
      let fq1 = [];
      let fq2 = [];
      let fq3 = [];

      if (this.$store.state.savedOnly === true) {
        fq1 = this.questions.filter(q => {
            if( saved.includes(q.id) ) {
              return true;
            }else{
              return false;
            }
        });
      } else {
        fq1 = this.questions;
      }

      if (this.$store.state.core === true) {
        fq2 = fq1.filter(q => q.core);
      } else {
        fq2 = fq1;
      }


  

      return fq2;
    }
  }
};
</script>

<style>
  .noresults {
    font-size: 1.4em;
    font-weight: 300;
    padding: 40px;
  }
</style>
