<template>
  <li v-if="showGroup(group)" :class="isActive(group)">
    <a @click="changeGroup(group)" :class="isActive(group)">
      {{ group.group }} <span class="group-count">({{ questionCount(group.questions) }})</span>
    </a>
    <div v-if="group.subgroups" >

        <div  v-for="subgroup in group.subgroups" :key="subgroup.ref" :parent="group">
           <ul class="subgroups" v-if="showGroup(subgroup)">
            <li><a  @click="changeGroup(group, subgroup.ref)">{{subgroup.group}} ({{ questionCount(subgroup.questions) }})</a></li>
            </ul>
        </div>
    </div>
   
  </li>
</template>

<script>
export default {
  name: "GroupHeading",
  props: {
    group: {
      type: Object
    }
  },
  methods: {
    changeGroup(group, anchor) {
      this.$store.commit("UPDATE_ACTIVE_GROUP", group);
      if (anchor) {
        setTimeout(function() {
          var VueScrollTo = require("vue-scrollto");
          var options = {
            easing: "ease-in-out",
            offset: -130,
            x: false,
            y: true
          };
          var target = "#" + anchor;
          VueScrollTo.scrollTo(target, 200, options);
        }, 10);
      } else {
        window.scrollTo(0, 0);
      }
    },

    isActive(group) {
      var linkClass = "";
      if (group == this.$store.state.activeGroup) {
        linkClass = "uk-active";
      }
      return linkClass;
    },

    showGroup(group){
      let count = 0;
      if( group.clients.includes(this.$store.state.clients) && group.scope.includes(this.$store.state.surveyScope) ) {
        count = this.questionCount(group.questions);
      }


     if(group?.subgroups !== undefined){
        group?.subgroups.forEach((sg) => {
     
           count += this.questionCount(sg.questions);
        });
     }


      if(count > 0 ){
        return true;
      } else {
        return false;
      }    
        
    },

    questionCount(questions) {
      let count = 0;
      let saved = this.$store.state.savedQuestions;
    
      let fq1 = [];
      let fq2 = [];
      let fq3 = [];
      let fq4 = [];

      if (this.$store.state.savedOnly === true) {
        fq1 = questions.filter(q => {
            if( saved.includes(q.id) ) {
              return true;
            }else{
              return false;
            }
        });
      } else {
        fq1 = questions;
      }

      if (this.$store.state.core === true) {
        fq2 = fq1.filter(q => q.core);
      } else {
        fq2 = fq1;
      }

      fq3 = fq2.filter(q => {

        let questionPasses = false;
        let channelFilters = this.$store.state.questionFilters;
    
        channelFilters.forEach(channel => {
          if( q.channels.includes(channel) ) {
            questionPasses = true;
          }
        });

        if(questionPasses == true){
          return true;
        }else{
          return false;
        }

      });
      count = fq3.length;
      // add statements to the count?
     
       fq3.forEach(q => {
          if(q.type == "scale"){
            count +=  this.statementCount(q.statements)
          }
         
        });

      return  count;
  
    },
    statementCount(statements) {

      let saved = this.$store.state.savedQuestions;
    
      let fq1 = [];
      let fq2 = [];
      let fq3 = [];
      let fq4 = [];

      if (this.$store.state.savedOnly === true) {
        fq1 = statements.filter(q => {
            if( saved.includes(q.id) ) {
              return true;
            }else{
              return false;
            }
        });
      } else {
        fq1 = statements;
      }

      if (this.$store.state.core === true) {
        fq2 = fq1.filter(q => q.core);
      } else {
        fq2 = fq1;
      }

      fq3 = fq2.filter(q => {

        let questionPasses = false;
        let channelFilters = this.$store.state.questionFilters;
    
        channelFilters.forEach(channel => {
          if( q.channels.includes(channel) ) {
            questionPasses = true;
          }
        });

        if(questionPasses == true){
          return true;
        }else{
          return false;
        }

      });
   
      return  fq3.length;
  
    }
  }
};
</script>

<style scoped>
.subgroups {
  margin-left: 2em;
}
a {
  color: #333;
}
a:hover {
  background-color: rgba(91, 72, 158, 0.05);
  color: #5b489e;
}


.group-count {
  font-weight: 100;
  color: #333;
  

}
</style>
