<template>
<div >
    <router-link :to="{ name: 'Home' }">
               <small>{{ $t('global_cmt') }}</small>
              <h1 >{{ $t('global_enhanced_question_bank') }}</h1>
              

            </router-link>
            <a class="langToggle" href="#" v-if="currentLocale == 'fr'" @click.stop="updateLocale('en')" >English</a> <a class="langToggle"  href="#" v-if="currentLocale == 'en'"  @click.stop="updateLocale('fr')">Français</a>
  </div>
</template>

<script>

export default {
  name: "GlobalHeader",

  methods: {
    updateLocale(locale) {
        this.$emit('locale-change', { newLocale: locale })

    }
  },
  computed: {
      currentLocale() {
      return this.$root.$i18n.locale;
    }
  }
  
    
};
</script>

<style lang="scss" scoped>
h1 {
    margin-bottom: 0.25em;
}
a.langToggle {
    margin-top: -20px;
    text-decoration: underline;
    font-size: 90%;
    color: #5b489e;
}
</style>
