<template>
  <div>
    
  <div v-if="showGroup(group)">
    <div>
      <div v-if="plain == false" style="" class="group-heading">
   
        <small>Section {{group.ref}}</small>
        <h2> {{ group.group }}</h2>

        <span class="groupnote">{{ group.note }}</span>
      </div>
      <div v-if="plain">
        <h3>Section {{group.ref}}:  {{ group.group }}</h3>
      </div>

      <question-list  :plain="plain"  :questions="group.questions"  />
      
 

        <div v-for="subgroup in group.subgroups" :key="subgroup.ref" >
      
          <div
            v-if="showGroup(subgroup)"
            class="subgroup"
            :id="subgroup.ref"
          >
            <h3>{{ subgroup.group }}</h3>
 
            <question-list :plain="plain"  :questions="subgroup.questions" />
          </div>
        </div>
 
    </div>
    <br /><br />
    
  </div>
  </div>
</template>

<script>
export default {
  name: "GroupQuestions",

  props: {
    group: {
      type: Object
    },
    plain: {
      default: false,
      type: Boolean
    }
  },

  methods: {

    showGroup(group){
   
      let count = 0;
      if( group.clients.includes(this.$store.state.clients) && group.scope.includes(this.$store.state.surveyScope) ) {
        count = this.questionCount(group.questions);
      }



     if(group?.subgroups !== undefined){
        group?.subgroups.forEach((sg) => {
          
           count += this.questionCount(sg.questions);
        });
     }
    
    
      if(count > 0 ){
        return true;
      } else {
        return false;
      }    
        
    },

    questionCount(questions) {
      
      

      let saved = this.$store.state.savedQuestions;
      let fq = questions;
      let fq1 = [];
      let fq2 = [];
   

      if (this.$store.state.savedOnly === true) {
        fq1 = fq.filter(q => {
            if( saved.includes(q.id) ) {
              return true;
            }else{
              return false;
            }
        });
      } else {
        fq1 = questions;
      }

      if (this.$store.state.core === true) {
        fq2 = fq1.filter(q => q.core);
      } else {
        fq2 = fq1;
      }


  

      return fq2.length;
    }
  }
};
</script>
<style scoped>
h2 {

    margin: -6px 0 0px 0;
    font-weight: 200;
    letter-spacing: 0.03em;
    color: #584B99;
    font-size: 1.8em;
}

.plaintext {
  float: right;
}

.subgroup {
  padding: 30px;
  margin: 30px 0px;
  border-top: 1px solid #ddd;
}
 .group-heading {
  padding: 10px; 
  text-align: left;

}
.group-heading small {
    font-weight: 400;
    font-size: 15px;
    opacity: 0.6;
    color: #3c3c3c;
  }

  .groupnote {
    font-weight: 300;
    font-size: 1.2em;
  }
</style>
