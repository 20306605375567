<template>
<div style="padding-left:20px;">

  <ul class="answerList">
    <li v-for="answer in answers" :key="answer.id">
      <span v-if="qtype == 'single'" class="circle"></span>
      <span v-if="qtype == 'multiple'" class="square"></span>
      {{ answer.a }}
      <question-list
        v-if="answer.questions"
        :questions="answer.questions"
        :channels="channels"
        :core="core"
        :plain="plain"
    
      />
    </li>
  </ul>
  </div>
</template>

<script>
export default {
  name: "AnswerList",
  props: {
    answers: {
      type: Array
    },
    channels: {
      type: Array
    },
    qtype: {
      type: String
    },
    core: {
      type: Boolean
    },
    plain: {
      type: Boolean,
      default: false
    }
  }

};
</script>

<style lang="scss">
.answerList li {
  padding: 0.2em 0;

  -webkit-user-select: all;  /* Chrome all / Safari all */
  -moz-user-select: all;     /* Firefox all */
  -ms-user-select: all;      /* IE 10+ */
    user-select: all;
}
.answerList {
  padding: 0 0 0 20px;
}
</style>
