<template>
  <span class="channelTags unselectable"  unselectable="on" style="user-select: none;" >

  
      <span  v-for="c in channelsToShow" :key="c.id">
        <span class="uk-label" :data-channel="c" v-html="$t(c)"></span>
      </span>
 
    
  </span>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    },
    channels: {
      type: Array
    },
    ac: {
      type: Array
    }
  },
  computed: {
    channelsToShow() {
      var channelsToShow = [];
      var channelTags = this.item.channels;
      var availableChannels = this.$store.state.availableChannels;

      availableChannels.forEach(function(c) {
        if (channelTags.includes(c)) {
          channelsToShow.push(c);
        }
      });


      return channelsToShow;
    }
  }
};
</script>
<style lang="scss" scoped>


.uk-label {
  margin-bottom: 3px;
  background: #8877C5;
  color: #fff;
  cursor: default !important; 
}
.uk-label[data-channel="phone"] {
  background: lighten(desaturate(#ea357f, 15), 10);
  color: #fff;
}
.uk-label[data-channel="in-person"] {
  background: #ff9710;
  color: #fff;
}
.uk-label[data-channel="web"] {
  background: lighten(desaturate(#60d03e, 15), 10);
  color: #fff;
}
.uk-label[data-channel="mail"] {
  background: lighten(desaturate(#2869f6, 15), 10);
  color: #fff;
}
.uk-label[data-channel="email"] {
  background: lighten(desaturate(#bb34f6, 15), 10);
  color: #fff;
}
</style>
