import { render, staticRenderFns } from "./ChannelTags.vue?vue&type=template&id=14fc6282&scoped=true&"
import script from "./ChannelTags.vue?vue&type=script&lang=js&"
export * from "./ChannelTags.vue?vue&type=script&lang=js&"
import style0 from "./ChannelTags.vue?vue&type=style&index=0&id=14fc6282&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14fc6282",
  null
  
)

export default component.exports